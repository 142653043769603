@tailwind base;
@tailwind components;
@tailwind utilities;

/* Default Page Styles */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #121212;
  color: #eaeaea;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Initial Loading Animation */
.loading {
    display: block;
    margin: auto;
    position: relative;
    text-align: center;
    width: 100%;
    height: 100vh;
    background-color: #121212;
}

.loading.hide{
    display: none;
}

.loader {
    height: 6px;
    width: 150px;
    background: linear-gradient(#FCF300 0 0), linear-gradient(#FCF300 0 0),#4e4949;
    background-size: 60% 100%;
    background-repeat: no-repeat;
    animation: linear-loader 3s infinite;
    border-radius: 3px;
}
 
@keyframes linear-loader {
    0%   {background-position:-150% 0,-150% 0}
    66%  {background-position: 250% 0,-150% 0}
    100% {background-position: 250% 0, 250% 0}
}

/* Profile Hand Wave Animation */
.wave {
	animation-name: wave-animation;
	animation-duration: 2.5s;
	animation-iteration-count: infinite; 
	transform-origin: 70% 70%;
	display: inline-block;
}
  
@keyframes wave-animation {
	0% { transform: rotate( 0.0deg) }
	10% { transform: rotate(14.0deg) } 
	20% { transform: rotate(-8.0deg) }
	30% { transform: rotate(14.0deg) }
	40% { transform: rotate(-4.0deg) }
	50% { transform: rotate(10.0deg) }
	60% { transform: rotate( 0.0deg) }
	100% { transform: rotate( 0.0deg) }
}  

/* Profile Page Scroll Down Animation */
.scrolldown {
	--color: #FCF300;
	--sizeX: 30px;
	--sizeY: 50px;
	position: relative;
	width: var(--sizeX);
	height: var(--sizeY);
	margin-left: var(sizeX / 2);
	border: calc(var(--sizeX) / 10) solid var(--color);
	border-radius: 50px;
	box-sizing: border-box;
	margin-bottom: 16px;
}

.scrolldown::before {
	content: "";
	position: absolute;
	bottom: 30px;
	left: 50%;
	width: 6px;
	height: 6px;
	margin-left: -3px;
	background-color: var(--color);
	border-radius: 100%;
	animation: scrolldown-anim 2s infinite;
	box-sizing: border-box;
	box-shadow: 0px -5px 3px 1px #ffffff66;
}

@keyframes scrolldown-anim {
	0% {
		opacity: 0;
		height: 6px;
	}

	40% {
		opacity: 1;
		height: 10px;
	}

	80% {
		transform: translate(0, 20px);
		height: 10px;
		opacity: 0;
	}

	100% {
		height: 3px;
		opacity: 0;
	}
}

.chevrons {
	padding: 6px 0 0 0;
	margin-left: -3px;
	margin-top: 48px;
	width: 30px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.chevrondown {
	margin-top: -6px;
	position: relative;
	border: solid var(--color);
	border-width: 0 3px 3px 0;
	display: inline-block;
	width: 10px;
	height: 10px;
	transform: rotate(45deg);
}

.chevrondown:nth-child(odd) {
	animation: pulse 500ms ease infinite alternate;
}

.chevrondown:nth-child(even) {
	animation: pulse 500ms ease infinite alternate 250ms;
}

@keyframes pulse {
	from {
		opacity: 0;
	}

	to {
		opacity: 0.5;
	}
}

/* Logo Slider Animation */
.loop-slider .slide-elems {
	animation-name: loop_elems_anim;
	animation-timing-function: linear;
	animation-iteration-count: infinite;
	animation-direction: var(--direction);
	animation-duration: var(--duration);
}
.fade_effect {
	pointer-events: none;
	background: linear-gradient(90deg, #121212, transparent 30%, transparent 70%, #121212);
	position: absolute;
	inset: 0;
}
@keyframes loop_elems_anim {
	0% {
		transform: translateX(0);
   }
	100% {
		transform: translateX(-50%);
   }
}
